<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
    <fw-panel-box
      title="Notificações por ler"
      icon="notification-on"
      :height="unreadNotificationsCounter > 0 ? 'sm' : '2xs'"
      :counter="unreadNotificationsCounter"
    >
      <template #default>
        <PanelNotificationList
          type="minimal"
          :show-header="false"
          filter-type="unread"
          :tags="['personal']"
          :app="'myuc'"
          paddingless
          :limit="25"
          @unread-count="unreadNotificationsCounter = $event"
        />
      </template>
    </fw-panel-box>

    <fw-panel-box title="Ações pendentes" :counter="0" icon="actions" height="2xs">
      <template #default>
        <div class="flex items-center justify-center text-sm text-gray-500 h-full">
          Não existem ações pendentes.
        </div>
      </template>
    </fw-panel-box>
  </div>
</template>

<script>
import PanelNotificationList from '@/fw-modules/fw-core-vue/notifications/components/panels/PanelNotificationList'

export default {
  components: {
    PanelNotificationList,
  },

  data() {
    return {
      stats: [],
      weeks: {},
      unreadNotificationsCounter: 0,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },
  },
}
</script>
